import './_contact_map.sass'

export default class {
  constructor() {
    let $map = $('#map');
    let latitude = parseFloat($map.attr('data-latitude'));
    let longitude = parseFloat($map.attr('data-longitude'));
    let position = {lat: latitude, lng: longitude};

    let map = new google.maps.Map($map[0], {
      center: position,
      zoom: 11,
      disableDefaultUI: true
    });

    let icon = {
      url: imagePath('./map-marker.svg'),
      scaledSize: new google.maps.Size(41, 31)
    };

    let marker = new google.maps.Marker({
      position: position,
      icon: icon,
      map: map
    });

  }
}
