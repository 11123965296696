import './_usps.sass'
import $ from 'jquery';
import 'slick-carousel'
import 'slick-carousel/slick/slick.scss'

export default class {
  constructor() {

    let $navSlider = $('._usps-nav-slider');
    let $contentSlider = $('._usps-slider');

    // Init navigation carousel
    $navSlider.slick({
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '._usps-slider',
      dots: false,
      focusOnSelect: true,
      centerMode: true
    });

    // Init main carousel
    $contentSlider.slick({
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToScroll: 1,
      slidesToShow: 1,
      arrows: false,
      dots: false,
      asNavFor: '._usps-nav-slider'
    });

    // Remove active class from all thumbnail slides
    $navSlider.find('.slick-slide').removeClass('slick-active');

    // Set active class to first thumbnail slides
    $navSlider.find('.slick-slide').first().addClass('slick-active');

    // On before slide change match active thumbnail to current slide
    $contentSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      $navSlider.find('.slick-slide').removeClass('slick-active');
      $navSlider.find('.slick-slide').eq(nextSlide).addClass('slick-active');
    });

    // Resize navslider
    $(window).resize(function(){
      $navSlider[0].slick.refresh();
    });

  }
}
