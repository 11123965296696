import './accessories.sass'
import IntroText from '../../shared/_intro_text'
import Categories from '../../shared/_categories'
import Materials from '../../shared/_materials'
import Contact from '../../shared/_contact'

export default class {
    constructor() {
      new IntroText();
      new Categories();
      new Materials();
      new Contact();
    }
}
