import './playgrounds.sass'
import IntroText from '../../shared/_intro_text'
import Image from '../../shared/_image'
import CategoryProducts from '../../shared/_category_products'
import Categories from '../../shared/_categories'
import Certificates from '../../shared/_certificates'
import References from '../../shared/_references'
import Contact from '../../shared/_contact'

export default class {
    constructor() {
      new IntroText();
      new Image();
      new CategoryProducts();
      new Categories();
      new Certificates();
      new References();
      new Contact();
    }
}
