import './gardens.sass'
import IntroText from '../../shared/_intro_text'
import Image from '../../shared/_image'
import Categories from '../../shared/_categories'
import References from '../../shared/_references'
import Contact from '../../shared/_contact'

export default class {
    constructor() {
      new IntroText();
      new Image();
      new Categories();
      new References();
      new Contact();
    }
}
