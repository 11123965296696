import './index.sass'
import IntroText from '../../../../../javascript/website/shared/_intro_image'
import ImageLinks from '../../../../../javascript/website/shared/_image_links'
import Info from '../../../../../javascript/website/shared/_info'
import Text from '../../../../../javascript/website/shared/_text'
import Image from '../../../../../javascript/website/shared/_image'
import Usps from '../../../../../javascript/website/shared/_usps'
import Contact from '../../../../../javascript/website/shared/_contact'

export default class {
    constructor() {
      new IntroText();
      new ImageLinks();
      new Info();
      new Text();
      new Image();
      new Usps();
      new Contact();
    }
}
