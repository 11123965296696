import './schools.sass'
import IntroText from '../../../../../javascript/website/shared/_intro_image'
import Image from '../../../../../javascript/website/shared/_image'
import Cards from '../../../../../javascript/website/shared/_cards'
import Usps from '../../../../../javascript/website/shared/_usps'
import References from '../../../../../javascript/website/shared/_references'
import Contact from '../../../../../javascript/website/shared/_contact'

export default class {
    constructor() {
      new IntroText();
      new Image();
      new Cards();
      new Usps();
      new References();
      new Contact();
    }
}
