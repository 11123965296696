import './_steps.sass'
import $ from 'jquery';
import 'slick-carousel'
import 'slick-carousel/slick/slick.scss'

export default class {
  constructor() {
    let $slider = $('._steps-slider');

    // Init main carousel
    $slider.slick({
      autoplay: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      arrows: true,
      appendArrows: '._steps-arrows',
      dots: true,
      appendDots: '._steps-dots',
    });
  }
}
