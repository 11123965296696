import './_header.sass'

export default class {
  constructor() {
    this.$headerNav = $('.header-nav');
    this.$toggler = this.$headerNav.find('.header-nav-toggler');

    this.$toggler.on('click', (e) => {
      this.handleToggleClick(e);
    });
  }

  handleToggleClick(event) {
    event.preventDefault();
    this.toggleNavigation();
  }

  toggleNavigation() {
    this.$headerNav.toggleClass('open')
  }

}
