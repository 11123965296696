/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable"
import "regenerator-runtime/runtime"

const images = require.context('../images', true);
window.imagePath = (name) => images(name, true);

import '../website.sass'

// Vendor
import SmoothScroll from '../vendor/smooth-scroll'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'
import LazyLoad from "vanilla-lazyload";
import '@fancyapps/fancybox/dist/jquery.fancybox'
import '@fancyapps/fancybox/dist/jquery.fancybox.css'

// Views
import Header from '../website/_header'
import Footer from '../website/_footer'
import MainIndex from '../website/main/index'
import PagesShow from '../website/pages/show'

import CustomerHome from '../website/customer/main/index'
import CustomerGardens from '../website/customer/main/gardens'
import CustomerPlaygrounds from '../website/customer/main/playgrounds'
import CustomerAccessories from '../website/customer/main/accessories'
import CustomerReferencesIndex from '../website/customer/references/index'
import CustomerReferencesShow from '../website/customer/references/show'
import CustomerAboutUs from '../website/customer/main/about_us'
import CustomerContact from '../website/customer/main/contact'

import ProfessionalHome from '../website/professional/main/index'
import ProfessionalLeasing from '../website/professional/main/leasing'
import ProfessionalSectors from '../website/professional/sectors/main/index'
import ProfessionalSectorsSchools from '../website/professional/sectors/main/schools'
import ProfessionalSectorsSpecial from '../website/professional/sectors/main/special'
import ProfessionalSectorsCatering from '../website/professional/sectors/main/catering'
import ProfessionalSectorsTownships from '../website/professional/sectors/main/townships'
import ProfessionalPlaygrounds from '../website/professional/main/playgrounds'
import ProfessionalAccessories from '../website/professional/main/accessories'
import ProfessionalReferencesIndex from '../website/professional/references/index'
import ProfessionalReferencesShow from '../website/professional/references/show'
import ProfessionalAboutUs from '../website/professional/main/about_us'
import ProfessionalContact from '../website/professional/main/contact'

import SitemapIndex from '../website/sitemap/index'

window.onload = function () {

  // Init viewport fix
  viewportUnitsBuggyfill.init();

  // Init smooth scrolling
  new SmoothScroll();

  // Lazy load images
  var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
  });

  // Set correct csrf token in cached partials
  $('input[name=authenticity_token]').val($('meta[name=csrf-token]').attr('content'));

  // Init header
  new Header();
  new Footer();

  // Import views
  const $body = $('body');
  const controller = $body.attr('data-controller');
  const action = $body.attr('data-action');

  (controller === 'website/main' && action === 'index') && new MainIndex();

  (controller === 'website/customer/main' && action === 'index') && new CustomerHome();
  (controller === 'website/customer/main' && action === 'gardens') && new CustomerGardens();
  (controller === 'website/customer/main' && action === 'playgrounds') && new CustomerPlaygrounds();
  (controller === 'website/customer/main' && action === 'accessories') && new CustomerAccessories();
  (controller === 'website/customer/references' && action === 'index') && new CustomerReferencesIndex();
  (controller === 'website/customer/references' && action === 'show') && new CustomerReferencesShow();
  (controller === 'website/customer/main' && action === 'about_us') && new CustomerAboutUs();
  (controller === 'website/customer/main' && action === 'contact') && new CustomerContact();

  (controller === 'website/professional/main' && action === 'index') && new ProfessionalHome();
  (controller === 'website/professional/main' && action === 'leasing') && new ProfessionalLeasing();
  (controller === 'website/professional/sectors/main' && action === 'index') && new ProfessionalSectors();
  (controller === 'website/professional/sectors/main' && action === 'schools') && new ProfessionalSectorsSchools();
  (controller === 'website/professional/sectors/main' && action === 'special') && new ProfessionalSectorsSpecial();
  (controller === 'website/professional/sectors/main' && action === 'catering') && new ProfessionalSectorsCatering();
  (controller === 'website/professional/sectors/main' && action === 'townships') && new ProfessionalSectorsTownships();
  (controller === 'website/professional/main' && action === 'playgrounds') && new ProfessionalPlaygrounds();
  (controller === 'website/professional/main' && action === 'accessories') && new ProfessionalAccessories();
  (controller === 'website/professional/references' && action === 'index') && new ProfessionalReferencesIndex();
  (controller === 'website/professional/references' && action === 'show') && new ProfessionalReferencesShow();
  (controller === 'website/professional/main' && action === 'about_us') && new ProfessionalAboutUs();
  (controller === 'website/professional/main' && action === 'contact') && new ProfessionalContact();

  (controller === 'website/pages' && action === 'show') && new PagesShow();

  (controller === 'website/sitemap' && action === 'index') && new SitemapIndex();

};

