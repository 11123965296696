import './contact.sass'
import ContactIntro from '../../shared/_contact_intro'
import ContactMap from '../../shared/_contact_map'
import Steps from '../../shared/_steps'
import Contact from '../../shared/_contact'

export default class {
    constructor() {
      new ContactIntro();
      new ContactMap();
      new Steps();
      new Contact();
    }
}
