import './_category_products.sass'
import $ from 'jquery';
import 'slick-carousel'
import 'slick-carousel/slick/slick.scss'

export default class {
  constructor() {
    let $slider = $('._category-products-slider');

    // Init main carousel
    $slider.slick({
      autoplay: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      arrows: true,
      appendArrows: '._category-products-arrows',
      dots: false,
    });
  }
}
