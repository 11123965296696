import './leasing.sass'
import IntroText from '../../shared/_intro_text'
import Image from '../../shared/_image'
import Info from '../../shared/_info'
import Pros from '../../shared/_pros'
import Steps from '../../shared/_steps'
import Contact from '../../shared/_contact'

export default class {
    constructor() {
      new IntroText();
      new Image();
      new Info();
      new Pros();
      new Steps();
      new Contact();
    }
}
