import './about_us.sass'
import IntroText from '../../shared/_intro_text'
import Image from '../../shared/_image'
import Usps from '../../shared/_usps'
import Steps from '../../shared/_steps'
import Certificates from '../../shared/_certificates'
import Contact from '../../shared/_contact'

export default class {
    constructor() {
      new IntroText();
      new Image();
      new Usps();
      new Steps();
      new Certificates();
      new Contact();
    }
}
