import './index.sass'
import IntroText from '../../shared/_intro_text'
import List from '../../references/_list'
import FeaturedList from '../../references/_featured_list'
import Contact from '../../shared/_contact'

export default class {
    constructor() {
      new IntroText();
      new Contact();
    }
}
