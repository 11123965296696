import './index.sass'
import IntroImage from '../../shared/_intro_image'
import Portal from '../../shared/_portal'
import Info from '../../shared/_info'
import Usps from '../../shared/_usps'
import References from '../../shared/_references'
import Contact from '../../shared/_contact'

export default class {
    constructor() {
      new IntroImage();
      new Portal();
      new Info();
      new Usps();
      new References();
      new Contact();
    }
}
